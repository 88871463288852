import React, { useEffect, useState } from "react";
import {
    Checkbox,
    Download,
    FormFooter,
    FormsMain,
    Statements,
} from "@components/components";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { debtorForm6Data } from "@config/forms";
import { checkedState, initialState, radioState } from "state";
import { AppRoute } from "@enums";
import { useFullForm } from "@hooks";
import type { Value } from "react-multi-date-picker";
import DatePicker from "react-multi-date-picker";

export const DebtorForm6 = () => {
    const { t } = useTranslation();
    const { title, statements, download } = debtorForm6Data(t);
    const {
        values,
        onChange,
        onSubmit,
        onCheckboxChange,
        checked,
        radioChecked,
        onRadioChange,
    } = useFullForm(formCallback, checkedState, initialState, radioState);
    const [name, setName] = useState<string>("");

    async function formCallback() {
        console.log("checked");
    }

    const [value, setValue] = useState<Value>(new Date());
    const [checkedArr, setCheckedArr] = useState({
        agree1: true,
        agree2_1: true,
        agree2_2: true,
        agree2_3: true,
    });
    const onHandelCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setCheckedArr({
            ...checkedArr,
            [event.target.name]: event.target.checked,
        });
    };

    const [validForm, setValidForm] = useState(false);
    const [addBank, setAddBank] = useState(false);
    const [addBank2, setAddBank2] = useState(false);
    const [isTopError, setIsTopError] = useState(false);
    useEffect(() => {
        if (validForm) setIsTopError(false);
    }, [validForm]);

    useEffect(() => {
        setName(Object(values).debtor_name);
        localStorage.setItem("caseNumber", Object(values).case_number);
        localStorage.setItem("debtorName", Object(values).debtor_name);
        localStorage.setItem("debtorId", Object(values).debtor_id);
        localStorage.setItem("debtorAddress", Object(values).debtor_address);
        localStorage.setItem("debtorEmail", Object(values).debtor_email);
        localStorage.setItem("debtor_phone", Object(values).debtor_phone);
        localStorage.setItem(
            "pre_debtor_phone",
            Object(values).pre_debtor_phone,
        );

        localStorage.setItem("addBank", Object(values).add_bank);
        localStorage.setItem("addBank2", Object(values).add_more);
        localStorage.setItem("amount", Object(values).amount);
        localStorage.setItem("date", Object(values).date);
        localStorage.setItem("firstPayment", Object(values).first_payment);
        localStorage.setItem("limit", Object(values).limit);
        localStorage.setItem("limit1", Object(values).limit1);

        localStorage.setItem("monthLimit", Object(values).month_limit);

        localStorage.setItem("amount3", Object(values).amount3);
        localStorage.setItem("current3", Object(values).current3);
        localStorage.setItem("summa", Object(values).summa);

        localStorage.setItem("claimantName1", Object(values).claimant_name1);
        localStorage.setItem("claimantId1", Object(values).claimant_id1);
        localStorage.setItem(
            "claimantContent1",
            Object(values).claimant_content1,
        );

        localStorage.setItem("job", Object(values).job);
        localStorage.setItem("claims", Object(values).claims);
        localStorage.setItem("balance", Object(values).balance);
        localStorage.setItem("cars", Object(values).cars);
        localStorage.setItem("tech", Object(values).tech);
        localStorage.setItem("relative", Object(values).relative);
        localStorage.setItem("donate", Object(values).donate);
        localStorage.setItem("liabilities", Object(values).liabilities);

        localStorage.setItem("placeOwner", Object(values).place_owner);
        localStorage.setItem("placeAddress", Object(values).place_address);
        localStorage.setItem("placeBroker", Object(values).place_broker);
        localStorage.setItem("placePost", Object(values).place_post);
        localStorage.setItem("placePhone", Object(values).place_phone);
        localStorage.setItem("pre_placePhone", Object(values).pre_place_phone);
        localStorage.setItem("placePrice", Object(values).place_price);
        localStorage.setItem("placeDate", Object(values).place_date);
        localStorage.setItem("notary", Object(values).notary);
        localStorage.setItem("priceProperty", Object(values).price_property);
        localStorage.setItem(
            "pricePropertyDate",
            Object(values).price_property_date,
        );

        localStorage.setItem("additional", Object(values).additional);
        localStorage.setItem("additional1", Object(values).additional1);
        localStorage.setItem("additional2", Object(values).additional2);
        localStorage.setItem("additional3", Object(values).additional3);

        localStorage.setItem(
            "re_dependent_name_1",
            Object(values).re_dependent_name_1,
        );
        localStorage.setItem(
            "re_dependent_id_1",
            Object(values).re_dependent_id_1,
        );
        localStorage.setItem(
            "re_dependent_name_2",
            Object(values).re_dependent_name_2,
        );
        localStorage.setItem(
            "re_dependent_id_2",
            Object(values).re_dependent_id_2,
        );
        localStorage.setItem(
            "re_dependent_name_3",
            Object(values).re_dependent_name_3,
        );
        localStorage.setItem(
            "re_dependent_id_3",
            Object(values).re_dependent_id_3,
        );
        localStorage.setItem(
            "re_dependent_name_4",
            Object(values).re_dependent_name_4,
        );
        localStorage.setItem(
            "re_dependent_id_4",
            Object(values).re_dependent_id_4,
        );
        localStorage.setItem(
            "re_dependent_name_5",
            Object(values).re_dependent_name_5,
        );
        localStorage.setItem(
            "re_dependent_id_5",
            Object(values).re_dependent_id_5,
        );
    }, [values]);

    useEffect(() => {
        localStorage.setItem("nb2_1", Object(checked).nb2_1);
        localStorage.setItem("nb2_2", Object(checked).nb2_2);
        localStorage.setItem("nb2_3", Object(checked).nb2_3);
        localStorage.setItem("nb2_4", Object(checked).nb2_4);
        localStorage.setItem("nb2_5", Object(checked).nb2_5);

        localStorage.setItem("nb3_1", Object(checked).n32_1);
        localStorage.setItem("nb3_2", Object(checked).nb3_2);
        localStorage.setItem("nb3_3", Object(checked).nb3_3);

        localStorage.setItem("nb4", Object(checked).nb4);

        localStorage.setItem("point1", Object(checked).point1);
        localStorage.setItem("point2", Object(checked).point2);
        localStorage.setItem("point3", Object(checked).point3);
        localStorage.setItem("point4", Object(checked).point4);
        localStorage.setItem("point5", Object(checked).point5);
        localStorage.setItem("point6", Object(checked).point6);

        localStorage.setItem("schedule", Object(checked).schedule);
        localStorage.setItem("sale", Object(checked).sale);
        localStorage.setItem("other", Object(checked).other);
        localStorage.setItem("refund", Object(checked).refund);
        localStorage.setItem("limitation", Object(checked).limitation);
        localStorage.setItem("minimum", Object(checked).minimum);

        localStorage.setItem("agree1", JSON.stringify(checkedArr.agree1));
        localStorage.setItem("agree2_1", JSON.stringify(checkedArr.agree2_1));
        localStorage.setItem("agree2_2", JSON.stringify(checkedArr.agree2_2));
        localStorage.setItem("agree2_3", JSON.stringify(checkedArr.agree2_3));
    }, [checked, checkedArr]);

    useEffect(() => {
        localStorage.setItem("bank", Object(radioChecked).bank);
        localStorage.setItem("bank2", Object(radioChecked).bank2);
    }, [radioChecked]);

    useEffect(() => {
        localStorage.setItem("re_claimant", Object(radioChecked).re_claimant);
        localStorage.setItem("re_debtor", Object(radioChecked).re_debtor);
        localStorage.setItem("re_dependent", Object(radioChecked).re_dependent);
    }, [radioChecked]);

    const validationSchema = Yup.object().shape(
        {
            debtorName: Yup.string().required("Name is required"),
            debtorId: Yup.string().min(8).max(11).required("Id is required"),
            debtorAddress: Yup.string().required("Address is required"),
            debtorEmail: Yup.string().email().required("Email is required"),
            amount: Yup.string().required("This field is required"),
            date: Yup.string().required("This field is required"),
            firstPayment: Yup.string().required("This field is required"),
        },
        [],
    );

    useEffect(() => {
        validationSchema
            .isValid({
                debtorName: Object(values).debtor_name,
                debtorId: Object(values).debtor_id,
                debtorAddress: Object(values).debtor_address,
                debtorEmail: Object(values).debtor_email,

                amount: Object(values).amount,
                date: Object(values).date,
                firstPayment: Object(values).first_payment,
            })
            .then((valid) => {
                setValidForm(true);
                setIsTopError(!valid);
            })
            .catch((err) => {
                setIsTopError(true);
                console.log(err.name);
                console.log(err.errors);
            });
    });
    const date = new Date();
    const currentDate = date.toISOString().split("T")[0];
    const current = value?.toString().split("T")[0];
    localStorage.setItem("currentDate1", current || currentDate);
    useEffect(() => {
        setAddBank(localStorage.getItem("bank") === "Muu krediidiasutus");
        setAddBank2(localStorage.getItem("bank2") === "Muu krediidiasutus ");
    });
    const allTrue = Object.values(checkedArr).every((value) => value);

    return (
        <FormsMain title={title} onChange={onChange} validForm={validForm}>
            <form onSubmit={onSubmit}>
                <div className="flex flex-col  items-start my-6">
                    <p className="mt-4 mb-2 text-lg font-semibold ">
                        {t("debtorForm6Data.debtorForm6.confirm_title")}
                    </p>
                    <div className="flex flex-col w-full base-border mb-2">
                        <p className="mb-1 text-lg text-[#4b4b4b]">
                            {t("debtorForm6Data.debtorForm6.date")}
                        </p>
                        <DatePicker
                            value={value}
                            onChange={setValue}
                            inputClass="w-1/2 text-lg p-2 border rounded-md border-[#4b4b4b]"
                        />
                    </div>
                </div>

                <Statements
                    data={statements}
                    onChange={onChange}
                    onCheckboxChange={onCheckboxChange}
                    checked={Object(checked)}
                    onRadioChange={onRadioChange}
                    radioChecked={Object(radioChecked)}
                    addBank={addBank}
                    addBank2={addBank2}
                />
                <Download data={download} nameLocal="downloadForm_7" />
                <Checkbox
                    name="agree1"
                    id="agree1"
                    label={t("debtorForm6Data.debtorForm6.checkbox_1")}
                    onCheckboxChange={onHandelCheckboxChange}
                    value="agree1"
                    checked={checkedArr.agree1}
                />
                <Checkbox
                    name="agree2_1"
                    id="agree2_1"
                    label={t("debtorForm6Data.debtorForm6.checkbox_2")}
                    onCheckboxChange={onHandelCheckboxChange}
                    value="agree2_1"
                    checked={checkedArr.agree2_1}
                />
                <Checkbox
                    name="agree2_2"
                    id="agree2_2"
                    label={t("debtorForm6Data.debtorForm6.checkbox_3")}
                    onCheckboxChange={onHandelCheckboxChange}
                    value="agree2_2"
                    checked={checkedArr.agree2_2}
                />
                <Checkbox
                    name="agree2_3"
                    id="agree2_3"
                    label={t("debtorForm6Data.debtorForm6.checkbox_4")}
                    onCheckboxChange={onHandelCheckboxChange}
                    value="agree2_3"
                    checked={checkedArr.agree2_3}
                />
                {!allTrue && (
                    <p className="text-[#f70303] text-center font-semibold my-6">
                        {t("debtorForm6Data.debtorForm6.warning")}
                    </p>
                )}
            </form>
            <FormFooter
                pdf={AppRoute.PDF_DOCUMENT_6}
                title={title}
                validForm={validForm}
                setIsTopError={setIsTopError}
                params={{
                    name,
                }}
            />
        </FormsMain>
    );
};
