import React, { useState } from "react";
import clsx from "clsx";
import { HiArrowNarrowRight } from "react-icons/hi";

interface INewsCard {
    status: string;
    title: string;
    date: string;
    path: string;
}

export const NewsCard: React.FC<INewsCard> = ({
    status,
    title,
    date,
    path,
}) => {
    const [hover, setHover] = useState(false);

    const changeStyleHover = (event: React.MouseEvent<HTMLDivElement>) => {
        const curDiv: HTMLDivElement = event.currentTarget;
        curDiv.style.backgroundColor = "#1A1A1F";
        setHover(true);
    };

    const changeStyleLeave = (event: React.MouseEvent<HTMLDivElement>) => {
        const curDiv: HTMLDivElement = event.currentTarget;
        curDiv.style.backgroundColor = "white";
        setHover(false);
    };

    return (
        <div
            className="flex flex-col justify-between flex-1 p-2 pr-0 text-white rounded-md cursor-pointer md:p-6 md:pr-16 "
            onMouseOver={changeStyleHover}
            onMouseLeave={changeStyleLeave}
        >
            {/*<div*/}
            {/*    className={clsx(*/}
            {/*        `px-[5px] h-4  uppercase text-center flex items-center tracking-[2px] text-[8px] font-semibold text-white rounded-sm w-fit bg-secondary`,*/}
            {/*        status ? "opacity-100" : "opacity-0",*/}
            {/*    )}*/}
            {/*>*/}
            {/*    {status}*/}
            {/*</div>*/}

            <a href={path} className="flex items-center justify-between">
                <p
                    className={clsx(
                        hover ? "text-white" : "text-neutral",
                        "pb-3 lg:text-3xl text-xl font-semibold w-[85%] ",
                    )}
                >
                    {title}
                </p>
                <div className={hover ? "block md:mr-0 mr-4" : "hidden"}>
                    <HiArrowNarrowRight className="text-2xl text-secondary" />
                </div>
            </a>
            <p className="text-lightGray">{date}</p>
        </div>
    );
};
