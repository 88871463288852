import { TFunction } from "i18next";

export const newsData = (t: TFunction<"translation", undefined>) => {
    return [
        {
            id: 1,
            status: "",
            title: `${t("news.newsData.0.title")}`,
            date: `${t("news.newsData.0.date")}`,
            path: "https://www.linkedin.com/feed/update/urn:li:activity:6964855933608841216",
        },
        {
            id: 2,
            status: "",
            title: `${t("news.newsData.1.title")}`,
            date: `${t("news.newsData.1.date")}`,
            path: "https://www.linkedin.com/feed/update/urn:li:activity:6922511941395443712",
        },
        {
            id: 3,
            status: "",
            title: `${t("news.newsData.2.title")}`,
            date: `${t("news.newsData.2.date")}`,
            path: "https://www.linkedin.com/feed/update/urn:li:activity:6915262203000500224",
        },
        {
            id: 4,
            status: "",
            title: `${t("news.newsData.3.title")}`,
            date: `${t("news.newsData.3.date")}`,
            path: "https://www.linkedin.com/feed/update/urn:li:activity:6912756086450110464",
        },
        {
            id: 5,
            status: "",
            title: `${t("news.newsData.4.title")}`,
            date: `${t("news.newsData.4.date")}`,
            path: "https://www.linkedin.com/feed/update/urn:li:activity:6904777384009064448",
        },
        {
            id: 6,
            status: "",
            title: `${t("news.newsData.5.title")}`,
            date: `${t("news.newsData.5.date")}`,
            path: "https://www.linkedin.com/feed/update/urn:li:activity:6901486626439098370",
        },
        {
            id: 7,
            status: "",
            title: `${t("news.newsData.6.title")}`,
            date: `${t("news.newsData.6.date")}`,
            path: "https://www.linkedin.com/feed/update/urn:li:activity:6909840112763768832",
        },
    ];
};
