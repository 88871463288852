import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { checkedState, initialState, radioState } from "state";
import * as Yup from "yup";
import { useFullForm } from "@hooks";
import { AppRoute } from "@enums";

import {
    Awared,
    Checkbox,
    Download,
    FormFooter,
    FormsMain,
    HasData,
    Section,
    Textarea,
    Tooltip,
} from "@components/components";
import { claimantForm4Data } from "@config/forms";

export const ClaimantForm4 = () => {
    const { t } = useTranslation();
    const {
        title,
        sections,
        debtorHasRepresentative,
        request,
        claim_clarification,
        download,
        awared,
        claimantHasRepresentative,
    } = claimantForm4Data(t);
    const { onChange, onSubmit, radioChecked, values, onRadioChange } =
        useFullForm(formCallback, initialState, checkedState, radioState);
    const [name, setName] = useState<string>("");
    const [checkedArr, setCheckedArr] = useState({ agree: true });
    const onHandelCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setCheckedArr({
            ...checkedArr,
            [event.target.name]: event.target.checked,
        });
    };

    useEffect(() => {
        localStorage.setItem("selected_titles_arr", JSON.stringify([title]));
        localStorage.setItem("selectTitleItem", title);
    }, []);

    async function formCallback() {
        console.log("checked");
    }

    const [validForm, setValidForm] = useState(false);
    const [isTopError, setIsTopError] = useState(false);
    useEffect(() => {
        if (validForm) setIsTopError(false);
    }, [validForm]);

    useEffect(() => {
        localStorage.setItem("claimantName4", Object(values).claimant_name);
        localStorage.setItem("claimantId4", Object(values).claimant_id);
        localStorage.setItem("claimantPost4", Object(values).claimant_post);

        localStorage.setItem("claimant_phone", Object(values).claimant_phone);
        localStorage.setItem(
            "pre_claimant_phone",
            Object(values).pre_claimant_phone,
        );

        localStorage.setItem("debtor_phone", Object(values).debtor_phone);
        localStorage.setItem(
            "pre_debtor_phone",
            Object(values).pre_debtor_phone,
        );

        localStorage.setItem(
            "claimantAddress4",
            Object(values).claimant_address,
        );

        localStorage.setItem("bankAccount", Object(values).bank_account);
        localStorage.setItem("bankName", Object(values).bank_name);

        localStorage.setItem(
            "reClaimantName4",
            Object(values).re_claimant_name,
        );
        localStorage.setItem("reClaimantId4", Object(values).re_claimant_id);
        localStorage.setItem(
            "reClaimantPost4",
            Object(values).re_claimant_post,
        );
        localStorage.setItem(
            "reClaimantPhone4",
            Object(values).re_claimant_phone,
        );
        localStorage.setItem(
            "pre_reClaimantPhone4",
            Object(values).pre_re_claimant_phone,
        );
        localStorage.setItem(
            "reClaimantAddress4",
            Object(values).re_claimant_address,
        );
        localStorage.setItem(
            "reClaimantAccount",
            Object(values).re_claimant_account,
        );
        localStorage.setItem("reClaimantBank", Object(values).re_claimant_bank);
        setName(Object(values).debtor_name);
        localStorage.setItem("debtorName4", Object(values).debtor_name);
        localStorage.setItem("debtorId4", Object(values).debtor_id);
        localStorage.setItem("debtorAddress4", Object(values).debtor_address);
        localStorage.setItem("debtorPost4", Object(values).debtor_post);
        localStorage.setItem("debtorPhone4", Object(values).debtor_phone);

        localStorage.setItem("reDebtorName4", Object(values).re_debtor_name);
        localStorage.setItem("reDebtorId4", Object(values).re_debtor_id);
        localStorage.setItem(
            "reDebtorAddress4",
            Object(values).re_debtor_address,
        );
        localStorage.setItem("reDebtorPost4", Object(values).re_debtor_post);
        localStorage.setItem("reDebtorPhone4", Object(values).re_debtor_phone);
        localStorage.setItem(
            "pre_reDebtorPhone4",
            Object(values).pre_re_debtor_phone,
        );
        localStorage.setItem("requestName", Object(values).request_name);
        localStorage.setItem("requestDate", Object(values).request_date);
        localStorage.setItem("requestResult", Object(values).request_result);
        localStorage.setItem("requestContent", Object(values).request_content);
        localStorage.setItem("requestSum", Object(values).request_sum);
        localStorage.setItem("requestNumber", Object(values).request_number);

        localStorage.setItem("download1", Object(values).download_1);
        localStorage.setItem("download2", Object(values).download_2);
        localStorage.setItem("download3", Object(values).download_3);
        localStorage.setItem("download4", Object(values).download_4);
        localStorage.setItem("download5", Object(values).download_5);
        localStorage.setItem("caseNumber", Object(values).case_number);
        localStorage.setItem("debtor_assets", Object(values).debtor_assets);
    }, [values]);

    useEffect(() => {
        localStorage.setItem("re_claimant", Object(radioChecked).re_claimant);
        localStorage.setItem("re_debtor", Object(radioChecked).re_debtor);
        localStorage.setItem("period", Object(radioChecked).period);
        localStorage.setItem("percent", Object(radioChecked).percent);
        localStorage.setItem("active", Object(radioChecked).active);
    }, [radioChecked]);

    useEffect(() => {
        localStorage.setItem("agree", JSON.stringify(checkedArr.agree));
    }, [checkedArr]);

    const validationSchema = Yup.object().shape(
        {
            claimantName: Yup.string().required("Name is required"),
            claimantId: Yup.string().min(8).max(11).required("Id is required"),
            claimantAddress: Yup.string().required("Address is required"),
            claimantPost: Yup.string().email().required("Post is required"),

            debtorName: Yup.string().required("Name is required"),
            debtorId: Yup.string().min(8).max(11).required("Id is required"),
            debtorAddress: Yup.string().required("Id is required"),
            debtorPost: Yup.string().email().required("Post is required"),

            requestName: Yup.string().required("This field is required"),
            requestDate: Yup.string().required("This field is required"),
            requestResult: Yup.string().required("This field is required"),
            requestContent: Yup.string().required("This field is required"),
            requestSum: Yup.string().required("This field is required"),
            requestNumber: Yup.string().required("This field is required"),
        },
        [],
    );

    useEffect(() => {
        validationSchema
            .isValid({
                claimantName: Object(values).claimant_name,
                claimantId: Object(values).claimant_id,
                claimantAddress: Object(values).claimant_address,
                claimantPost: Object(values).claimant_post,
                debtorName: Object(values).debtor_name,
                debtorId: Object(values).debtor_id,
                debtorAddress: Object(values).debtor_address,
                debtorPost: Object(values).debtor_post,
                requestName: Object(values).request_name,
                requestDate: Object(values).request_date,
                requestResult: Object(values).request_result,
                requestContent: Object(values).request_content,
                requestSum: Object(values).request_sum,
                requestNumber: Object(values).request_number,
            })
            .then((valid) => {
                setValidForm(true);
            })
            .catch((err) => {
                console.log(err.name);
                console.log(err.errors);
            });
    });

    return (
        <FormsMain title={title} onChange={onChange} validForm={validForm}>
            <form onSubmit={onSubmit}>
                <Section
                    data={sections[0]}
                    key={sections[0].id}
                    onChange={onChange}
                    className="grid grid-cols-1 gap-4 md:grid-cols-2"
                />

                <HasData
                    data={claimantHasRepresentative}
                    radioChecked={radioChecked}
                    onRadioChange={onRadioChange}
                />

                {Object(radioChecked).re_claimant ===
                    t(
                        "claimantForm4Data.claimantHasRepresentative.values.0.value",
                    ) && (
                    <Section
                        data={sections[1]}
                        key={sections[1].id}
                        onChange={onChange}
                        className="grid grid-cols-1 gap-4 md:grid-cols-2"
                    />
                )}
                <Section
                    data={sections[2]}
                    key={sections[2].id}
                    onChange={onChange}
                    className="grid grid-cols-1 gap-4 md:grid-cols-2"
                />
                <HasData
                    data={debtorHasRepresentative}
                    radioChecked={radioChecked}
                    onRadioChange={onRadioChange}
                />
                {Object(radioChecked).re_debtor ===
                    t(
                        "claimantForm4Data.debtorHasRepresentative.values.0.value",
                    ) && (
                    <Section
                        data={sections[3]}
                        key={sections[3].id}
                        onChange={onChange}
                        className="grid grid-cols-1 gap-4 md:grid-cols-2"
                    />
                )}
                <Section
                    data={request}
                    onChange={onChange}
                    className="grid grid-cols-1 gap-4 md:grid-cols-2"
                />
                {claim_clarification.map((item) => (
                    <HasData
                        data={item}
                        key={item.id}
                        radioChecked={radioChecked}
                        onRadioChange={onRadioChange}
                    />
                ))}
                <div className="my-6">
                    <div className="flex flex-col w-full base-border mb-2">
                        <p className="mb-1 font-semibold text-lg text-[#333333]">
                            {t("claimantForm4Data.debtor_title")}
                            <Tooltip
                                hint={t("claimantForm4Data.debtor_hint")}
                            />
                        </p>
                        <Textarea
                            name="debtor_assets"
                            onChange={onChange}
                            className="w-1/2"
                            rows={2}
                        />
                    </div>
                </div>

                <Awared data={awared} base dark upper />
                <Download data={download} nameLocal="downloadForm_4" />
                <div className="mt-4">
                    <Checkbox
                        name="agree"
                        id="agree"
                        label={t("claimantForm4Data.checkbox")}
                        onCheckboxChange={onHandelCheckboxChange}
                        value="agree"
                        checked={checkedArr.agree}
                    />
                </div>
            </form>
            <FormFooter
                pdf={AppRoute.PDF_DOCUMENT_4}
                title={title}
                validForm={validForm}
                setIsTopError={setIsTopError}
                params={{
                    name,
                }}
            />
        </FormsMain>
    );
};
