import React, {useEffect, useState} from "react";

export const useDeviceWidhtDetect = (checkWidth: number) => {
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowResize = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", handleWindowResize);
        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    return width <= checkWidth;
};

export const useDeviceCardWidth = (flag :boolean): number => {
    const [width, setWidth] = useState<number>(0);


    const handleWindowResize = () => {
        if (window.innerWidth < 768) setWidth(400);
        if (window.innerWidth >= 768 && window.innerWidth < 1024) setWidth(440);
        else setWidth(500);
    };
    const handleWindowResizeFull = () => {
        if (window.innerWidth >= 1440 ) setWidth(1320);
        else setWidth(window.innerWidth-96);
    };

    useEffect(()=>{
        if (!flag) handleWindowResize();
        else handleWindowResizeFull()
    })

    useEffect(() => {
        window.addEventListener("resize", handleWindowResize);
        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    useEffect(() => {
        window.addEventListener("resize", handleWindowResizeFull);
        return () => {
            window.removeEventListener("resize", handleWindowResizeFull);
        };
    }, []);

    return width;
};