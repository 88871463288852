import React, { useEffect, useMemo, useState } from "react";
import { useFile } from "@providers/FileProvider";
import { useQueryParams } from "@hooks";
import {
    get_blank_data,
    get_form_data,
    get_pdf_form,
    getFileComponent,
} from "@helpers";
import {
    TableClaimantForm1,
    TableClaimantForm2,
    TableClaimantForm3,
    TableClaimantForm4,
    TableClaimantForm5,
    TableDebtorForm5,
    TableDebtorForm6,
} from "@components/tables";
import SuccessIcon from "@assets/images/success.jpg";
import { Modal } from "@components/common";
import { DokobitApi } from "@api/Dokobit";
import { useNavigate } from "react-router";
import { RotatingLines } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import { debtorForm6Data } from "@config/forms";

interface IParams {
    name: string;
    path: string;
}

interface IFileError {
    errorId: string;
    message: string;
}

interface IFile {
    name: string;
    size: number;
    type: string;
    url: string;
}

const formMap = {
    "/pdf_1": "downloadForm_1",
    "/pdf_2": "downloadForm_2",
    "/pdf_3": "downloadForm_3",
    "/pdf_4": "downloadForm_4",
    "/pdf_5": "downloadForm_6",
    "/pdf_6": "downloadForm_7",
    "/pdf_7": "downloadForm_5",
};

export const DigitalSign: React.FC = () => {
    const { t } = useTranslation();
    const { statements } = debtorForm6Data(t);
    const params = useQueryParams() as unknown as IParams;
    const { signingToken, signerToken, upload, error, loading } = useFile();
    const [opacity, setOpacity] = useState<number>(0);
    const [fileError, setFileError] = useState<IFileError | null>(null);
    const [signError, setSignError] = useState<string[]>([]);
    const [hasError, setHasError] = useState<boolean>(() => false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [files, setFiles] = useState<IFile[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            const block = document.getElementById("isTop");
            block?.scrollIntoView({ behavior: "smooth", block: "start" });
        }, 100);
    }, []);

    useEffect(() => {
        if (Object.keys(params).length) {
            const keyDownload = Object(formMap)[params.path] as string;
            const filesString = localStorage.getItem(keyDownload) || "[]";
            setFiles(JSON.parse(filesString));
            if (!signingToken && !signerToken) {
                const { name } = params;
                let fname = "";
                let sname = "";
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (name === "undefined" || !name || name === true) {
                    fname = "undefined";
                    sname = "undefined";
                } else {
                    [fname, sname] = name.split("%20");
                }
                getFileComponent("active-form")
                    .then((file) => {
                        // console.log(URL.createObjectURL(file));
                        upload(file, fname, sname ? sname : " ");
                    })
                    .catch((_error: IFileError) => setFileError(() => _error));
            }
        }
    }, [params]);

    useEffect(() => {
        if (!signingToken && !signerToken) {
            setOpacity(() => 0);
        } else {
            setOpacity(() => 1);
        }
    }, [signingToken, signerToken]);

    const Form = useMemo(() => {
        switch (params.path) {
            case "/pdf_1":
                return <TableClaimantForm1 id="active-form" />;
            case "/pdf_2":
                return <TableClaimantForm2 id="active-form" />;
            case "/pdf_3":
                return <TableClaimantForm3 id="active-form" />;
            case "/pdf_4":
                return <TableClaimantForm4 id="active-form" />;
            case "/pdf_5":
                return <TableDebtorForm5 id="active-form" />;
            case "/pdf_6":
                return <TableDebtorForm6 id="active-form" />;
            case "/pdf_7":
                return <TableClaimantForm5 id="active-form" />;
            default:
                return <div id="active-form">Undefined page</div>;
        }
    }, [params.path]);

    if (loading) return <div>Loading</div>;

    if (fileError) {
        setTimeout(() => {
            navigate("/forms");
        }, 9000);
        return (
            <Modal
                isShown={true}
                modalContent={<div>{fileError.message}</div>}
                headerText={`File error: ${fileError.errorId}`}
                hide={() => false}
                timer
                delay={9000}
            />
        );
    }

    if (error) {
        console.log(error);
        setTimeout(() => {
            navigate("/forms");
        }, 9000);
        return (
            <Modal
                isShown={true}
                modalContent={<div>{error.message}</div>}
                headerText={`Error: ${error.code}`}
                hide={() => false}
                timer
                delay={9000}
            />
        );
    }

    Isign.onSignError = function (errors: string[]) {
        console.log(errors);
        setSignError(() => errors);
        setHasError(() => true);
    };

    Isign.onSignSuccess = function () {
        const url_file = `https://gateway.dokobit.com/signing/${signingToken}/download?access_token=${signerToken}`;
        const documents = [url_file, ...files.map((file) => file.url)];
        const pdfForm = get_pdf_form();
        const blank = get_blank_data(t);
        const formBody = get_form_data();

        const checkIsInArray = (
            sources: string[],
            target: { id: number; title: string }[],
        ): boolean => {
            let flag = false;
            sources.forEach((name) => {
                target.forEach(({ title }) => {
                    if (
                        name.toLowerCase().trim() === title.toLowerCase().trim()
                    )
                        flag = true;
                });
            });
            return flag;
        };

        DokobitApi.debtor(blank, formBody, url_file).catch(null);

        if (params.path !== "/pdf_6" && params.path !== "/pdf_5") {
            DokobitApi.claimant(documents, formBody).catch(null);
        } else {
            DokobitApi.signature(documents, pdfForm, blank).catch(null);
        }

        if (params.path === "/pdf_6") {
            const forms = statements.slice(1, 4);
            console.log(checkIsInArray(pdfForm.blanks, forms));
            if (checkIsInArray(pdfForm.blanks, forms)) {
                DokobitApi.csv(blank, formBody).catch(null);
            }
        }
        setIsSuccess(true);
    };

    return (
        <>
            <div
                style={{
                    position: "absolute",
                    opacity: 0,
                    top: -9999,
                }}
            >
                {Form}
            </div>
            {opacity ? null : (
                <RotatingLines
                    strokeColor="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="96"
                    visible={true}
                />
            )}
            {/*<input*/}
            {/*    type="file"*/}
            {/*    onChange={(e) => {*/}
            {/*        const files = e.target.files;*/}
            {/*        if (files) {*/}
            {/*            upload(files[0], "Name", "Surname");*/}
            {/*        }*/}
            {/*    }}*/}
            {/*/>*/}
            <iframe
                id="isign-gateway"
                width="100%"
                height="100%"
                style={{ minHeight: "1058px", opacity }} //et
                src={`
                https://gateway.dokobit.com/signing/${signingToken}?access_token=${signerToken}&_locale=et 
            `}
            ></iframe>
            {hasError ? (
                <Modal
                    isShown={hasError}
                    modalContent={<div>{signError.join(", ")}</div>}
                    headerText="Unable to sign document"
                    hide={() => {
                        setHasError(() => false);
                        setSignError(() => []);
                    }}
                    timer
                    delay={9000}
                />
            ) : null}
            {isSuccess ? (
                <Modal
                    isShown={isSuccess}
                    modalContent={
                        <div className="flex flex-col items-center justify-center p-[50px]">
                            <img
                                src={SuccessIcon}
                                alt="Success"
                                className="mb-[25px] h-[160px] w-[160px]"
                            />
                            <h3>AVALDUS EDUKALT SAADETUD</h3>
                        </div>
                    }
                    headerText={""}
                    hide={() => setIsSuccess(false)}
                    timer
                    delay={9000}
                />
            ) : null}
        </>
    );
};
