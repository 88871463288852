import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { checkedState, initialState } from "state";
import { useFullForm } from "@hooks";
import {
    Awared,
    Checkbox,
    CheckedContainer,
    Compensation,
    Details,
    Download,
    FormFooter,
    FormsMain,
    Section,
} from "@components/components";
import { claimantForm1Data } from "@config/forms";
import { AppRoute } from "@enums";

export const ClaimantForm1 = () => {
    const { t } = useTranslation();
    const {
        title,
        sections,
        attached,
        details,
        compensation,
        awared,
        download,
    } = claimantForm1Data(t);
    const [validForm, setValidForm] = useState(false);
    const [name, setName] = useState<string>("");
    const { onChange, onSubmit, values, checked, onCheckboxChange } =
        useFullForm(initialState, checkedState);
    const [checkedArr, setCheckedArr] = useState({ agree: true });
    const onHandelCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setCheckedArr({
            ...checkedArr,
            [event.target.name]: event.target.checked,
        });
    };
    useEffect(() => {
        localStorage.setItem("agree", JSON.stringify(checkedArr.agree));
    }, [checkedArr]);

    useEffect(() => {
        localStorage.setItem("selected_titles_arr", JSON.stringify([title]));
        localStorage.setItem("selectTitleItem", title);
    }, []);

    useEffect(() => {
        localStorage.setItem("childName1", Object(values).child_name_1);
        localStorage.setItem("childId1", Object(values).child_id_1);
        localStorage.setItem("childAddress1", Object(values).child_address_1);

        localStorage.setItem("childName2", Object(values).child_name_2);
        localStorage.setItem("childId2", Object(values).child_id_2);
        localStorage.setItem("childAddress2", Object(values).child_address_2);

        localStorage.setItem("childName3", Object(values).child_name_3);
        localStorage.setItem("childId3", Object(values).child_id_3);
        localStorage.setItem("childAddress3", Object(values).child_address_3);

        localStorage.setItem("childName4", Object(values).child_name_4);
        localStorage.setItem("childId4", Object(values).child_id_4);
        localStorage.setItem("childAddress4", Object(values).child_address_4);

        localStorage.setItem("childName5", Object(values).child_name_5);
        localStorage.setItem("childId5", Object(values).child_id_5);
        localStorage.setItem("childAddress5", Object(values).child_address_5);

        localStorage.setItem("parentName", Object(values).parent_name);
        localStorage.setItem("parentId", Object(values).parent_id);
        localStorage.setItem("parentAddress", Object(values).parent_address);
        localStorage.setItem("parentEmail", Object(values).parent_email);
        localStorage.setItem("parent_phone", Object(values).parent_phone);
        localStorage.setItem(
            "pre_parent_phone",
            Object(values).pre_parent_phone,
        );

        setName(Object(values).debtor_name);
        localStorage.setItem("debtorName", Object(values).debtor_name);
        localStorage.setItem("debtorId", Object(values).debtor_id);
        localStorage.setItem("debtorAddress", Object(values).debtor_address);
        localStorage.setItem("debtorEmail", Object(values).debtor_email);
        localStorage.setItem("debtor_phone", Object(values).debtor_phone);
        localStorage.setItem(
            "pre_debtor_phone",
            Object(values).pre_debtor_phone,
        );

        localStorage.setItem("judgmentNumber", Object(values).judgment_number);
        localStorage.setItem("court", Object(values).court);
        localStorage.setItem("country", Object(values).country);
        localStorage.setItem("judgmentDate", Object(values).judgment_date);

        localStorage.setItem("compBank", Object(values).comp_bank);
        localStorage.setItem("compHolder", Object(values).comp_holder);
        localStorage.setItem("compCurrent", Object(values).comp_current);
        localStorage.setItem("caseNumber", Object(values).case_number);
    }, [values]);

    useEffect(() => {
        localStorage.setItem("point1", Object(checked).point1);
        localStorage.setItem("point2", Object(checked).point2);
        localStorage.setItem("point3", Object(checked).point3);
        localStorage.setItem("point4", Object(checked).point4);
        localStorage.setItem("point5", Object(checked).point5);
        localStorage.setItem("point6", Object(checked).point6);
        localStorage.setItem("point7", Object(checked).point7);
    }, [checked]);

    const validationSchema = Yup.object().shape(
        {
            childName: Yup.string().required("Name is required"),
            childId: Yup.string().min(11).max(11).required("Id is required"),
            childAddress: Yup.string().required("Address is required"),
            parentName: Yup.string().required("Name is required"),
            parentId: Yup.string().min(11).max(11).required("Id is required"),
            parentAddress: Yup.string().required("Address is required"),
            parentEmail: Yup.string().email().required("Email is required"),
            debtorName: Yup.string().required("Name is required"),
            debtorId: Yup.string().min(11).max(11).required("Id is required"),
            debtorAddress: Yup.string().required("Address is required"),
            debtorEmail: Yup.string().email().required("Email is required"),
        },
        [],
    );
    useEffect(() => {
        validationSchema
            .isValid({
                childName: Object(values).child_name_1,
                childId: Object(values).child_id_1,
                childAddress: Object(values).child_address_1,
                parentName: Object(values).parent_name,
                parentId: Object(values).parent_id,
                parentEmail: Object(values).parent_email,
                parentAddress: Object(values).parent_address,
                debtorName: Object(values).debtor_name,
                debtorId: Object(values).debtor_id,
                debtorEmail: Object(values).debtor_email,
                debtorAddress: Object(values).debtor_address,
            })
            .then((valid) => {
                setValidForm(true);
            })
            .catch((err) => {
                console.log(err.name);
                console.log(err.errors);
            });
    });
    return (
        <>
            <FormsMain title={title} onChange={onChange} validForm={validForm}>
                <form onSubmit={onSubmit}>
                    {sections.map((section) => (
                        <Section
                            data={section}
                            key={section.id}
                            onChange={onChange}
                            className="grid grid-cols-1 gap-4 md:grid-cols-2"
                        />
                    ))}
                    <Details data={details} onChange={onChange} />
                    <Compensation data={compensation} onChange={onChange} />
                    <CheckedContainer
                        data={attached}
                        onCheckboxChange={onCheckboxChange}
                        checked={Object(checked)}
                    />

                    <Awared data={awared} base dark upper />
                    <Download data={download} nameLocal="downloadForm_1" />
                    <Checkbox
                        name="agree"
                        id="agree"
                        label={t("claimantForm1Data.checkbox")}
                        onCheckboxChange={onHandelCheckboxChange}
                        value="agree"
                        checked={checkedArr.agree}
                    />
                </form>
                <FormFooter
                    pdf={AppRoute.PDF_DOCUMENT_1}
                    title={title}
                    validForm={validForm}
                    params={{
                        name,
                    }}
                />
            </FormsMain>
        </>
    );
};
