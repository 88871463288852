import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { initialState } from "state";
import { useFullForm } from "@hooks";
import { AppRoute } from "@enums";
import {
    Checkbox,
    Download,
    FormFooter,
    FormsMain,
    Section,
} from "@components/components";
import { claimantForm2Data } from "@config/";

export const ClaimantForm2 = () => {
    const { t } = useTranslation();
    const { title, sections, points, download } = claimantForm2Data(t);
    const { onChange, onSubmit, values } = useFullForm(initialState, length);
    const [validForm, setValidForm] = useState(false);
    const [name, setName] = useState<string>("");
    const [checkedArr, setCheckedArr] = useState({ agree: true });
    const onHandelCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setCheckedArr({
            ...checkedArr,
            [event.target.name]: event.target.checked,
        });
    };

    useEffect(() => {
        localStorage.setItem("selected_titles_arr", JSON.stringify([title]));
        localStorage.setItem("selectTitleItem", title);
    }, []);

    useEffect(() => {
        setName(Object(values).debtor_name);
        localStorage.setItem("debtorName", Object(values).debtor_name);
        localStorage.setItem("debtorId", Object(values).debtor_id);
        localStorage.setItem("debtorAddress", Object(values).debtor_address);
        localStorage.setItem("debtorEmail", Object(values).debtor_email);
        localStorage.setItem("debtor_phone", Object(values).debtor_phone);
        localStorage.setItem(
            "pre_debtor_phone",
            Object(values).pre_debtor_phone,
        );

        localStorage.setItem("debtorName1", Object(values).debtor_name_1);
        localStorage.setItem("debtorId1", Object(values).debtor_id_1);

        localStorage.setItem("email", Object(values).email);

        localStorage.setItem("caseNumber", Object(values).case_number);
    }, [values]);

    useEffect(() => {
        localStorage.setItem("agree", JSON.stringify(checkedArr.agree));
    }, [checkedArr]);

    const validationSchema = Yup.object().shape(
        {
            debtorName: Yup.string().required("Name is required"),
            debtorId: Yup.string().min(8).max(11).required("Id is required"),
            debtorAddress: Yup.string().required("Address is required"),
            debtorEmail: Yup.string().email().required("Email is required"),
            debtorName1: Yup.string().required("Name is required"),
            debtorId1: Yup.string().min(8).max(11).required("Id is required"),
        },
        [],
    );
    useEffect(() => {
        validationSchema
            .isValid({
                debtorName: Object(values).debtor_name,
                debtorId: Object(values).debtor_id,
                debtorAddress: Object(values).debtor_address,
                debtorEmail: Object(values).debtor_email,
                debtorName1: Object(values).debtor_name_1,
                debtorId1: Object(values).debtor_id_1,
            })
            .then((valid) => {
                setValidForm(true);
            })
            .catch((err) => {
                console.log(err.name);
                console.log(err.errors);
            });
    });

    return (
        <FormsMain title={title} onChange={onChange} validForm={validForm}>
            <form onSubmit={onSubmit}>
                {sections.map((section) => (
                    <Section
                        data={section}
                        key={section.id}
                        onChange={onChange}
                        className="grid grid-cols-1 gap-4 md:grid-cols-2"
                    />
                ))}

                <div className="mt-6">
                    {points.map((point) => (
                        <p className="mb-4 text-lg" key={point.id}>
                            {point.content}
                        </p>
                    ))}
                </div>
                <Download data={download} nameLocal="downloadForm_2" />
                <Checkbox
                    name="agree"
                    id="agree"
                    label={t("claimantForm2Data.checkbox")}
                    onCheckboxChange={onHandelCheckboxChange}
                    value="agree"
                    checked={checkedArr.agree}
                />
                {/*<CheckedContainer*/}
                {/*    data={agreement}*/}
                {/*    onCheckboxChange={onCheckboxChange}*/}
                {/*    checked={Object(checked)}*/}
                {/*/>*/}
            </form>
            <FormFooter
                pdf={AppRoute.PDF_DOCUMENT_2}
                title={title}
                validForm={validForm}
                params={{
                    name,
                }}
            />
        </FormsMain>
    );
};
